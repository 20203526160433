import PlayerProfilePage from "pages/employeeCard/player-profile/PlayerProfilePage";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
} from "react-router-dom";
import AuthGuard from "./auth/AuthGuard";
import ForgotPasswordPage from "./auth/ForgotPasswordPage";
import GoogleCodePage from "./auth/GoogleCodePage";
import LoginPage from "./auth/LoginPage";
import RequireCompanyFeaturesGuard from "./auth/RequireCompanyFeaturesGuard";
import RequirePermissionsGuard from "./auth/RequirePermissionsGuard";
import ResetPasswordWithCodePage from "./auth/ResetPasswordWithCodePage";
import EmployeePage from "./pages/employeeCard/EmployeePage";
import PersonInfoPage from "./pages/employeeCard/person-info/PersonInfoPage";
import SlackConnectWithCodePage from "./pages/settings/integrations/slack/SlackConnectWithCodePage";
import Layout from "./pages/layout/Layout";
import NewsPage from "./pages/news/NewsPage";
import NotFoundPage from "./pages/NotFoundPage";
import { SuspenseWrapper } from "./utils/components";
import { AppPermissions, Features } from "./utils/consts";
import { lazyRoutes } from "./utils/lazyRoutes";
import EmployeeAchievementsPage from "./pages/employeeCard/employee-achievements/EmployeeAchievementsPage";
import MerchDeliveryInfoPage from "pages/employeeCard/delivery-info/MerchDeliveryInfoPage";
import ErrorRoute from "utils/components/errorRoute/ErrorRoute";
import AzureTokenPage from "./auth/azureLogin/AzureTokenPage";
import MicrosoftConnectPage from "./pages/settings/integrations/microsoftEntraId/MicrosoftConnectPage";
import TimeOffProfilePage from "pages/employeeCard/time-off-profile/TimeOffProfile";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<ErrorRoute />}>
      <Route path="/" element={<Navigate to="/news" />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/forget-password" element={<ForgotPasswordPage />} />
      <Route
        path="/forget-password-code"
        element={<ResetPasswordWithCodePage />}
      />
      <Route path="/code" element={<GoogleCodePage />} />
      <Route path="/azureAuth" element={<AzureTokenPage />} />
      <Route element={<AuthGuard />}>
        <Route element={<Layout />} errorElement={<ErrorRoute />}>
          <Route
            path="/news"
            element={<NewsPage />}
            errorElement={<ErrorRoute />}
          />

          <Route path="/employee/:userId" element={<EmployeePage />}>
            <Route
              index
              element={<EmployeeAchievementsPage />}
              errorElement={<ErrorRoute />}
            />

            {/*Works like permissions guard: available only for admin with permission ViewAndEditUserEmployeeCards, for current user, for other users with permission EmployeeCard_ViewUsersEmployeeCard  */}
            <Route
              path="/employee/:userId/personal-info"
              element={<PersonInfoPage />}
              errorElement={<ErrorRoute />}
            />

            {/*Works also like guard: content available only if gamification is available and if user is current user or admin with permission ViewAndEditUserEmployeeCards or EmployeeCard_ViewPlayerProfile*/}
            <Route
              path="/employee/:userId/player-info"
              element={<PlayerProfilePage />}
              errorElement={<ErrorRoute />}
            />

            {/*Works like permissions guard: available only for admin with permission EmployeeCard_ViewAndEditMerchDeliveryInfo, for current user, for other users with permission EmployeeCard_ViewAndEditMerchDeliveryInfo  */}
            <Route
              path="/employee/:userId/delivery-info"
              element={<MerchDeliveryInfoPage />}
              errorElement={<ErrorRoute />}
            />

            {/*Works like guard: content available only if timeOffFeature is available and if user is current user or admin with permission TimeOff_ViewOtherUserBalance or TimeOff_ViewOtherUserBacklog*/}
            <Route
              path="/employee/:userId/time-off"
              element={<TimeOffProfilePage />}
              errorElement={<ErrorRoute />}
            />
          </Route>

          <Route
            element={
              <RequireCompanyFeaturesGuard features={[Features.Gamification]} />
            }
            errorElement={<ErrorRoute />}
          >
            <Route
              path="/achievements"
              element={
                <SuspenseWrapper>
                  <lazyRoutes.AchievementsPage />
                </SuspenseWrapper>
              }
              errorElement={<ErrorRoute />}
            >
              <Route
                index
                element={<Navigate to="/achievements/list" replace />}
              />
              <Route
                path="list"
                element={
                  <SuspenseWrapper>
                    <lazyRoutes.AchievementsList />
                  </SuspenseWrapper>
                }
                errorElement={<ErrorRoute />}
              />
              <Route
                path="calendar"
                element={
                  <SuspenseWrapper>
                    <lazyRoutes.CompanyCalendar />
                  </SuspenseWrapper>
                }
                errorElement={<ErrorRoute />}
              />
            </Route>
            <Route
              path="/achievement-categories"
              element={
                <SuspenseWrapper>
                  <lazyRoutes.AchievementCategoriesPage />
                </SuspenseWrapper>
              }
              errorElement={<ErrorRoute />}
            />
            <Route
              path="/achievement-categories/:categoryId"
              element={
                <SuspenseWrapper>
                  <lazyRoutes.AchievementCategoryPage />
                </SuspenseWrapper>
              }
              errorElement={<ErrorRoute />}
            />
            <Route
              path="/achievements/:achievementId"
              element={
                <SuspenseWrapper>
                  <lazyRoutes.AchievementPage />
                </SuspenseWrapper>
              }
              errorElement={<ErrorRoute />}
            />
            <Route
              path="/leaderboard"
              element={
                <SuspenseWrapper>
                  <lazyRoutes.LeaderboardPage />
                </SuspenseWrapper>
              }
              errorElement={<ErrorRoute />}
            />
            <Route
              element={
                <RequirePermissionsGuard
                  permissions={[
                    AppPermissions.Achievements_CreateEditCompanyAchievements,
                  ]}
                />
              }
              errorElement={<ErrorRoute />}
            >
              <Route
                path="/achievements/add-achievement"
                element={
                  <SuspenseWrapper>
                    <lazyRoutes.AddEditAchievementPage />
                  </SuspenseWrapper>
                }
                errorElement={<ErrorRoute />}
              />
              <Route
                path="/achievement-categories/:categoryId/add-achievement"
                element={
                  <SuspenseWrapper>
                    <lazyRoutes.AddEditAchievementPage />
                  </SuspenseWrapper>
                }
                errorElement={<ErrorRoute />}
              />
              <Route
                path="/achievements/:achievementId/edit"
                element={
                  <SuspenseWrapper>
                    <lazyRoutes.AddEditAchievementPage />
                  </SuspenseWrapper>
                }
                errorElement={<ErrorRoute />}
              />
              <Route
                path="/achievements-applications"
                element={
                  <SuspenseWrapper>
                    <lazyRoutes.AchievementsEventsPage />
                  </SuspenseWrapper>
                }
                errorElement={<ErrorRoute />}
              />
            </Route>
            <Route
              path="/analytics/"
              element={
                <RequirePermissionsGuard
                  permissions={[
                    AppPermissions.ViewCompanysAnalytics,
                    AppPermissions.Achievements_ViewAnalytics,
                    AppPermissions.Activity_ViewAnalytics,
                  ]}
                />
              }
              errorElement={<ErrorRoute />}
            >
              <Route
                path=""
                element={
                  <SuspenseWrapper>
                    <lazyRoutes.AnalyticsPage />
                  </SuspenseWrapper>
                }
              >
                <Route
                  element={
                    <RequirePermissionsGuard
                      permissions={[AppPermissions.Achievements_ViewAnalytics]}
                    />
                  }
                  errorElement={<ErrorRoute />}
                >
                  <Route
                    path="badges"
                    element={
                      <SuspenseWrapper>
                        <lazyRoutes.BadgesAnalyticsPage />
                      </SuspenseWrapper>
                    }
                  />
                </Route>
                <Route
                  element={
                    <RequirePermissionsGuard
                      permissions={[AppPermissions.Achievements_ViewAnalytics]}
                    />
                  }
                  errorElement={<ErrorRoute />}
                >
                  <Route
                    path="achievements"
                    element={
                      <SuspenseWrapper>
                        <lazyRoutes.AchievementsAnalyticsPage />
                      </SuspenseWrapper>
                    }
                  />
                </Route>
                <Route
                  element={
                    <RequirePermissionsGuard
                      permissions={[AppPermissions.Achievements_ViewAnalytics]}
                    />
                  }
                  errorElement={<ErrorRoute />}
                >
                  <Route
                    path="activity"
                    element={
                      <SuspenseWrapper>
                        <lazyRoutes.ActivityAnalyticsPage />
                      </SuspenseWrapper>
                    }
                  />
                </Route>
              </Route>
            </Route>
            <Route
              element={
                <RequirePermissionsGuard
                  permissions={[AppPermissions.Gamification_ManageAutoEvents]}
                />
              }
              errorElement={<ErrorRoute />}
            >
              <Route
                path="/manage-auto-events"
                element={
                  <SuspenseWrapper>
                    <lazyRoutes.AutoEventsManagementPage />
                  </SuspenseWrapper>
                }
                errorElement={<ErrorRoute />}
              />
            </Route>
            <Route
              element={
                <RequirePermissionsGuard
                  permissions={[
                    AppPermissions.Gamification_Achievements_ViewAchievementsArchive,
                  ]}
                />
              }
              errorElement={<ErrorRoute />}
            >
              <Route
                path="/events/archive"
                element={
                  <SuspenseWrapper>
                    <lazyRoutes.AchievementsArchivePage />
                  </SuspenseWrapper>
                }
                errorElement={<ErrorRoute />}
              />

              <Route
                path="/events/archive/:achievementId"
                element={
                  <SuspenseWrapper>
                    <lazyRoutes.AchievementArchivePage />
                  </SuspenseWrapper>
                }
                errorElement={<ErrorRoute />}
              />
            </Route>

            <Route
              path="/merch-store"
              element={
                <SuspenseWrapper>
                  <lazyRoutes.MerchStorePage />
                </SuspenseWrapper>
              }
              errorElement={<ErrorRoute />}
            />
            <Route
              path="/merch-store/merches/:merchId"
              element={
                <SuspenseWrapper>
                  <lazyRoutes.MerchPage />
                </SuspenseWrapper>
              }
              errorElement={<ErrorRoute />}
            />
            <Route
              element={
                <RequirePermissionsGuard
                  permissions={[AppPermissions.MerchOrders_ViewAndManageOrders]}
                />
              }
              errorElement={<ErrorRoute />}
            >
              <Route
                path="/merch-delivery"
                element={
                  <SuspenseWrapper>
                    <lazyRoutes.MerchDeliveryPage />
                  </SuspenseWrapper>
                }
                errorElement={<ErrorRoute />}
              />
            </Route>
            <Route
              element={
                <RequirePermissionsGuard
                  permissions={[AppPermissions.Merch_ViewMerchDashboard]}
                />
              }
              errorElement={<ErrorRoute />}
            >
              <Route
                path="/merch-items-management"
                element={
                  <SuspenseWrapper>
                    <lazyRoutes.MerchItemsManagementPage />
                  </SuspenseWrapper>
                }
                errorElement={<ErrorRoute />}
              />
            </Route>
          </Route>
          <Route
            element={
              <RequirePermissionsGuard
                permissions={[
                  AppPermissions.ManageBadges,
                  AppPermissions.AddRemoveBadges,
                ]}
              />
            }
            errorElement={<ErrorRoute />}
          >
            <Route
              path="/manage-badges"
              element={
                <SuspenseWrapper>
                  <lazyRoutes.ManageBadgesPage />
                </SuspenseWrapper>
              }
              errorElement={<ErrorRoute />}
            />
          </Route>

          <Route
            element={
              <RequireCompanyFeaturesGuard features={[Features.TimeOff]} />
            }
            errorElement={<ErrorRoute />}
          >
            <Route
              element={
                <RequirePermissionsGuard
                  permissions={[
                    AppPermissions.TimeOff_ViewAddEditDeletePolicies,
                  ]}
                />
              }
              errorElement={<ErrorRoute />}
            >
              <Route
                path="/time-off-policy-types"
                element={
                  <SuspenseWrapper>
                    <lazyRoutes.TimeOffPolicyTypesPage />
                  </SuspenseWrapper>
                }
                errorElement={<ErrorRoute />}
              />
              <Route
                path="/time-off-policy-types/:policyTypeId"
                element={
                  <SuspenseWrapper>
                    <lazyRoutes.TimeOffPolicyTypePage />
                  </SuspenseWrapper>
                }
                errorElement={<ErrorRoute />}
              />
              <Route
                path="/time-off-policy-types/:policyTypeId/add-policy"
                element={
                  <SuspenseWrapper>
                    <lazyRoutes.AddEditTimeOffPolicyPage />
                  </SuspenseWrapper>
                }
                errorElement={<ErrorRoute />}
              />
              <Route
                path="/time-off-policy-types/:policyTypeId/edit-policy/:policyId"
                element={
                  <SuspenseWrapper>
                    <lazyRoutes.AddEditTimeOffPolicyPage />
                  </SuspenseWrapper>
                }
                errorElement={<ErrorRoute />}
              />
              <Route
                path="/time-off-policy-types/:policyTypeId/policies/:policyId"
                element={
                  <SuspenseWrapper>
                    <lazyRoutes.TimeOffPolicyPage />
                  </SuspenseWrapper>
                }
                errorElement={<ErrorRoute />}
              />
              <Route
                element={
                  <RequirePermissionsGuard
                    permissions={[
                      AppPermissions.TimeOff_ViewAndManageOtherUsersRequests,
                    ]}
                  />
                }
                errorElement={<ErrorRoute />}
              >
                <Route
                  path="/time-off-requests"
                  element={
                    <SuspenseWrapper>
                      <lazyRoutes.TimeOffRequestsManagementPage />
                    </SuspenseWrapper>
                  }
                  errorElement={<ErrorRoute />}
                />
              </Route>
              <Route
                element={
                  <RequirePermissionsGuard
                    permissions={[AppPermissions.TimeOff_ViewCalendar]}
                  />
                }
                errorElement={<ErrorRoute />}
              >
                <Route
                  path="/time-off-calendar"
                  element={
                    <SuspenseWrapper>
                      <lazyRoutes.TimeOffCalendar />
                    </SuspenseWrapper>
                  }
                  errorElement={<ErrorRoute />}
                />
              </Route>
            </Route>
          </Route>

          <Route
            element={
              <RequirePermissionsGuard
                permissions={[
                  AppPermissions.AddEditRemoveCompanyMember,
                  AppPermissions.ChangeCompanysLogoAndColors,
                  AppPermissions.ViewAndEditCompanyData,
                ]}
              />
            }
            errorElement={<ErrorRoute />}
          >
            <Route
              path="/account-settings"
              element={
                <SuspenseWrapper>
                  <lazyRoutes.AccountSettingsPage />
                </SuspenseWrapper>
              }
              errorElement={<ErrorRoute />}
            />
          </Route>
          <Route
            element={
              <RequirePermissionsGuard
                permissions={[
                  AppPermissions.ViewAndEditCompanyData,
                  AppPermissions.ActivateDeactivateCompanys,
                ]}
              />
            }
            errorElement={<ErrorRoute />}
          >
            <Route
              path="/company-management"
              element={
                <SuspenseWrapper>
                  <lazyRoutes.CompanyManagementPage />
                </SuspenseWrapper>
              }
              errorElement={<ErrorRoute />}
            />
          </Route>
          <Route
            element={
              <RequirePermissionsGuard
                permissions={[
                  AppPermissions.UserGroupsManagement_ViewAddEditDeleteUserGroups,
                ]}
              />
            }
            errorElement={<ErrorRoute />}
          >
            <Route
              path="/group-management"
              element={
                <SuspenseWrapper>
                  <lazyRoutes.GroupManagementPage />
                </SuspenseWrapper>
              }
              errorElement={<ErrorRoute />}
            />
            <Route
              path="/group-management/create-group"
              element={
                <SuspenseWrapper>
                  <lazyRoutes.AddEditUserGroupPage />
                </SuspenseWrapper>
              }
              errorElement={<ErrorRoute />}
            />
            <Route
              path="/group-management/edit-group/:userGroupId"
              element={
                <SuspenseWrapper>
                  <lazyRoutes.AddEditUserGroupPage />
                </SuspenseWrapper>
              }
              errorElement={<ErrorRoute />}
            />
            <Route
              path="/group-management/groups/:userGroupId"
              element={
                <SuspenseWrapper>
                  <lazyRoutes.UserGroupPage />
                </SuspenseWrapper>
              }
              errorElement={<ErrorRoute />}
            />
          </Route>
          <Route
            element={
              <RequirePermissionsGuard
                permissions={[AppPermissions.ViewCompanyOrgStructure]}
              />
            }
            errorElement={<ErrorRoute />}
          >
            <Route
              path="/org-structure"
              element={
                <SuspenseWrapper>
                  <lazyRoutes.OrgStructurePage />
                </SuspenseWrapper>
              }
              errorElement={<ErrorRoute />}
            />
            <Route
              path="/org-structure/:userId"
              element={
                <SuspenseWrapper>
                  <lazyRoutes.OrgStructurePage />
                </SuspenseWrapper>
              }
              errorElement={<ErrorRoute />}
            />
            <Route
              path="/directory"
              element={
                <SuspenseWrapper>
                  <lazyRoutes.DirectoryPage />
                </SuspenseWrapper>
              }
              errorElement={<ErrorRoute />}
            />
          </Route>

          <Route
            element={
              <RequirePermissionsGuard
                permissions={[AppPermissions.ManageIntegrations]}
              />
            }
            errorElement={<ErrorRoute />}
          >
            <Route
              path="/integrations"
              element={
                <SuspenseWrapper>
                  <lazyRoutes.IntegrationsPage />
                </SuspenseWrapper>
              }
              errorElement={<ErrorRoute />}
            />

            <Route
              path="/integrations/slack"
              element={
                <SuspenseWrapper>
                  <lazyRoutes.SlackIntegrationPage />
                </SuspenseWrapper>
              }
              errorElement={<ErrorRoute />}
            />

            <Route
              path="/integrations/slack/connect-code"
              element={<SlackConnectWithCodePage />}
              errorElement={<ErrorRoute />}
            />

            <Route
              path="/integrations/microsoft"
              element={
                <SuspenseWrapper>
                  <lazyRoutes.MicrosoftIntegrationPage />
                </SuspenseWrapper>
              }
              errorElement={<ErrorRoute />}
            />

            <Route
              path="/integrations/microsoft/connect"
              element={<MicrosoftConnectPage />}
              errorElement={<ErrorRoute />}
            />

            <Route
              path="/integrations/peopleForce"
              element={
                <SuspenseWrapper>
                  <lazyRoutes.PeopleForceIntegrationPage />
                </SuspenseWrapper>
              }
              errorElement={<ErrorRoute />}
            />

            <Route
              path="/integrations/bambooHR"
              element={
                <SuspenseWrapper>
                  <lazyRoutes.BambooHrIntegrationPage />
                </SuspenseWrapper>
              }
              errorElement={<ErrorRoute />}
            />
          </Route>

          <Route
            element={
              <RequirePermissionsGuard
                permissions={[AppPermissions.RolesManagement]}
              />
            }
            errorElement={<ErrorRoute />}
          >
            <Route
              path="/system-settings"
              element={
                <SuspenseWrapper>
                  <lazyRoutes.SystemSettingsPage />
                </SuspenseWrapper>
              }
              errorElement={<ErrorRoute />}
            >
              <Route
                index
                element={
                  <SuspenseWrapper>
                    <lazyRoutes.AssignRoleTab />
                  </SuspenseWrapper>
                }
                errorElement={<ErrorRoute />}
              />
              <Route
                path="setting-role"
                element={
                  <SuspenseWrapper>
                    <lazyRoutes.SettingRoleTab />
                  </SuspenseWrapper>
                }
                errorElement={<ErrorRoute />}
              />
              <Route path="access-management" element={<Outlet />}>
                <Route
                  index
                  element={
                    <SuspenseWrapper>
                      <lazyRoutes.AccessManagementTab />
                    </SuspenseWrapper>
                  }
                  errorElement={<ErrorRoute />}
                />
                <Route
                  path="new-role"
                  element={
                    <SuspenseWrapper>
                      <lazyRoutes.AddEditRolePage />
                    </SuspenseWrapper>
                  }
                  errorElement={<ErrorRoute />}
                />
                <Route
                  path="role/:roleId"
                  element={
                    <SuspenseWrapper>
                      <lazyRoutes.AddEditRolePage />
                    </SuspenseWrapper>
                  }
                  errorElement={<ErrorRoute />}
                />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Route>
  )
);

export default router;
