import { lazy } from "react";

const AccountSettingsPage = lazy(
  () => import("../pages/settings/account-settings/AccountSettingsPage")
);
const AnalyticsPage = lazy(() => import("../pages/analytics/AnalyticsPage"));
const CompanyManagementPage = lazy(
  () => import("../pages/settings/company-management/CompanyManagementPage")
);
const EmployeePage = lazy(() => import("../pages/employeeCard/EmployeePage"));
const AchievementsPage = lazy(
  () => import("../pages/events/achievements/AchievementsPage") 
);
const AchievementsList = lazy(
  () => import("../pages/events/achievements/achievementsList/AchievementsList")
);

const CompanyCalendar = lazy(
  () =>
    import(
      "../pages/events/achievements/companyCalendar/CompanyCalendarPage"
    )
);
const AchievementPage = lazy(
  () => import("../pages/events/achievement/AchievementPage")
);
const AddEditAchievementPage = lazy(
  () => import("../pages/events/achievement/AddEditAchievementPage")
);
const AchievementCategoriesPage = lazy(
  () =>
    import("../pages/events/achievementCategories/AchievementCategoriesPage")
);
const AchievementCategoryPage = lazy(
  () => import("../pages/events/achievementCategories/AchievementCategoryPage")
);
const AchievementsAnalyticsPage = lazy(
  () =>
    import("../pages/analytics/analytics-achevements/AchievementsAnalyticsPage")
);
const BadgesAnalyticsPage = lazy(
  () => import("../pages/analytics/analytics-badges/AnalyticsBadgesPage")
);
const AchievementsEventsPage = lazy(
  () => import("../pages/events/achievementsEvents/AchievementsEventsPage")
);
const LeaderboardPage = lazy(
  () => import("../pages/leaderboard/LeaderboardPage")
);
const IntegrationsPage = lazy(
  () => import("../pages/settings/integrations/IntegrationsPage")
);
const SlackIntegrationPage = lazy(
  () => import("../pages/settings/integrations/slack/SlackIntegrationPage")
);
const MicrosoftIntegrationPage = lazy(
  () =>
    import(
      "../pages/settings/integrations/microsoftEntraId/MicrosoftIntegrationPage"
    )
);
const PeopleForceIntegrationPage = lazy(
  () =>
    import(
      "../pages/settings/integrations/peopleForce/PeopleForceIntegrationPage"
    )
);
const BambooHrIntegrationPage = lazy(
  () =>
    import(
      "../pages/settings/integrations/bambooHR/BambooHrIntegrationPage"
    )
);
const ManageBadgesPage = lazy(
  () => import("../pages/manage-badges/ManageBadgesPage")
);
const MerchDeliveryPage = lazy(
  () => import("../pages/merch/merchDelivery/MerchDeliveryPage")
);
const MerchStorePage = lazy(
  () => import("../pages/merch/merchStore/MerchStorePage")
);
const MerchPage = lazy(
  () => import("../pages/merch/merchStore/merch/MerchPage")
);
const MerchItemsManagementPage = lazy(
  () => import("../pages/merch/merchItemsManagement/MerchItemsManagementPage")
);

const DirectoryPage = lazy(
  () => import("../pages/people/directory/DirectoryPage")
);
const OrgStructurePage = lazy(
  () => import("../pages/people/orgStructure/OrgStructurePage")
);

const AccessManagementTab = lazy(
  () =>
    import(
      "../pages/settings/system-settings/accessManagementTab/AccessManagementTab"
    )
);
const AddEditRolePage = lazy(
  () =>
    import(
      "../pages/settings/system-settings/accessManagementTab/addEditRole/AddEditRolePage"
    )
);
const AssignRoleTab = lazy(
  () => import("../pages/settings/system-settings/assignRoleTab/AssignRoleTab")
);
const SettingRoleTab = lazy(
  () =>
    import("../pages/settings/system-settings/settingRoleTab/SettingRoleTab")
);
const SystemSettingsPage = lazy(
  () => import("../pages/settings/system-settings/SystemSettingsPage")
);
const AutoEventsManagementPage = lazy(
  () => import("../pages/events/manageAutoEvents/AutoEventsManagementPage")
);
const AchievementsArchivePage = lazy(
  () => import("../pages/events/achievementsArchive/AchievementsArchivePage")
);
const AchievementArchivePage = lazy(
  () => import("../pages/events/achievementsArchive/AchievementArchivePage")
);

const GroupManagementPage = lazy(
  () => import("../pages/settings/group-management/GroupManagementPage")
);

const UserGroupPage = lazy(
  () => import("../pages/settings/group-management/UserGroupPage")
);

const AddEditUserGroupPage = lazy(
  () =>
    import(
      "../pages/settings/group-management/addEditUserGroup/AddEditUserGroupPage"
    )
);

const ActivityAnalyticsPage = lazy(
  () => import("../pages/analytics/analytics-activity/ActivityAnalyticsPage")
);

const TimeOffPolicyTypesPage = lazy(
  () => import("../pages/timeOff/timeOffPolicyTypes/TimeOffPolicyTypesPage")
);

const TimeOffPolicyTypePage = lazy(
  () => import("../pages/timeOff/timeOffPolicyTypes/TimeOffPolicyTypePage")
);

const AddEditTimeOffPolicyPage = lazy(
  () =>
    import(
      "pages/timeOff/timeOffPolicies/AddEditTimeOffPolicy/AddEditTimeOffPolicyPage"
    )
);

const TimeOffPolicyPage = lazy(
  () => import("../pages/timeOff/timeOffPolicyPage/TimeOffPolicyPage")
);

const TimeOffRequestsManagementPage = lazy(
  () =>
    import(
      "../pages/timeOff/timeOffRequestsManagement/TimeOffRequestsManagementPage"
    )
);

const TimeOffCalendar = lazy(
  () => import("../pages/timeOff/timeOffCalendar/TimeOffCalendar")
);

export const lazyRoutes = {
  AccountSettingsPage,
  AnalyticsPage,
  CompanyManagementPage,
  EmployeePage,
  AchievementPage,
  AddEditAchievementPage,
  AchievementCategoriesPage,
  AchievementCategoryPage,
  AchievementsAnalyticsPage,
  AchievementsEventsPage,
  LeaderboardPage,
  IntegrationsPage,
  SlackIntegrationPage,
  MicrosoftIntegrationPage,
  PeopleForceIntegrationPage,
  BambooHrIntegrationPage,
  ManageBadgesPage,
  MerchDeliveryPage,
  MerchStorePage,
  MerchItemsManagementPage,
  DirectoryPage,
  OrgStructurePage,
  AccessManagementTab,
  AddEditRolePage,
  AssignRoleTab,
  SettingRoleTab,
  SystemSettingsPage,
  AutoEventsManagementPage,
  AchievementsArchivePage,
  AchievementArchivePage,
  MerchPage,
  GroupManagementPage,
  UserGroupPage,
  AddEditUserGroupPage,
  AchievementsPage,
  ActivityAnalyticsPage,
  BadgesAnalyticsPage,
  TimeOffPolicyTypesPage,
  TimeOffPolicyTypePage,
  AddEditTimeOffPolicyPage,
  TimeOffPolicyPage,
  TimeOffRequestsManagementPage,
  TimeOffCalendar,
  AchievementsList,
  CompanyCalendar,
};
