import { getDateTimeString } from "utils/methods";

export const getAchievementTimeline = (
  locale?: Locale,
  startDate?: string,
  activeUntil?: string
): string => {
  if (!locale) return "";

  if (!startDate || !activeUntil) return "";

  const shortStartDate = getDateTimeString(
    startDate,
    "fullDate",
    locale
  ).replace(/[,.]/g, "");
  const shortUntilDate = getDateTimeString(
    activeUntil,
    "fullDate",
    locale
  ).replace(/[,.]/g, "");

  return `${shortStartDate} - ${shortUntilDate}`;
};
