import { IntegrationTypes } from "../../../utils/consts";

export type IntegrationInfo = {
  type: string;
  displayNameLocKey: string;
  shortDescriptionLocKey: string;
  longDescriptionLocKey: string[];
  logoLink: string;
  routeLink: string;
  active: string;
  inactive: string;
  integrationActiveStatus: boolean;
};

export const slackIntegrationInfo: IntegrationInfo = {
  type: IntegrationTypes.Slack,
  displayNameLocKey: "systems_slack_displayName",
  shortDescriptionLocKey: "systems_slack_shortDescription",
  longDescriptionLocKey: [
    "systems_slack_longDescription1",
    "systems_slack_longDescription2",
    "systems_slack_longDescription3",
  ],
  logoLink: "/img/integrations/slack-logo.png",
  routeLink: "/integrations/slack",
  active: "status_active",
  inactive: "status_inactive",
  integrationActiveStatus: false,
};

export const microsoftIntegrationInfo: IntegrationInfo = {
  type: IntegrationTypes.MicrosoftEntraId,
  displayNameLocKey: "systems_microsoft_displayName",
  shortDescriptionLocKey: "systems_microsoft_shortDescription",
  longDescriptionLocKey: [
    "systems_microsoft_longDescription1",
    "systems_microsoft_longDescription2",
  ],
  logoLink: "/img/integrations/microsoft-logo.png",
  routeLink: "/integrations/microsoft",
  active: "status_active",
  inactive: "status_inactive",
  integrationActiveStatus: false,
};

export const peopleForceIntegrationInfo: IntegrationInfo = {
  type: IntegrationTypes.PeopleForce,
  displayNameLocKey: "systems_peopleForce_displayName",
  shortDescriptionLocKey: "systems_peopleForce_shortDescription",
  longDescriptionLocKey: [
    "systems_peopleForce_longDescription1",
    "systems_peopleForce_longDescription2",
  ],
  logoLink: "/img/integrations/peopleForce-logo.png",
  routeLink: "/integrations/peopleForce",
  active: "status_active",
  inactive: "status_inactive",
  integrationActiveStatus: false,
};

export const bambooHrIntegrationInfo: IntegrationInfo = {
  type: IntegrationTypes.BambooHR,
  displayNameLocKey: "systems_bambooHR_displayName",
  shortDescriptionLocKey: "systems_bambooHR_shortDescription",
  longDescriptionLocKey: [
    "systems_bambooHR_longDescription1",
    "systems_bambooHR_longDescription2",
  ],
  logoLink: "/img/integrations/bambooHR-logo.png",
  routeLink: "/integrations/bambooHR",
  active: "status_active",
  inactive: "status_inactive",
  integrationActiveStatus: false,
};

export const supportedIntegrationTypes: IntegrationInfo[] = [
  slackIntegrationInfo,
  microsoftIntegrationInfo,
  peopleForceIntegrationInfo,
  bambooHrIntegrationInfo,
];
