import { FC, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useParams } from "react-router-dom";
import { BadgeMessageSystem } from "../../api/types/news";
import { NoImageUrl } from "../../appConsts";
import {
  getAvatarSign,
  getDateTimeString,
  getFullName,
} from "../../utils/methods";
import classNames from "classnames";
import useLocale from "localization/useLocale";
import ReactionsBadgeMessage from "./ReactionsBadgeMessage";
import Icon from "../../utils/components/Icon";
import { Whisper } from "rsuite";
import { useAppSelector } from "../../store";
import { OverlayTriggerHandle } from "rsuite/esm/internals/Overlay/OverlayTrigger";
import { AutoEventTypes } from "../../api/types/manageAutoEventsTypes";
import ReactQuill from "react-quill";
import { safeJsonParse } from "utils/methods/safeJsonParse";
import UserAvatar from "../../utils/components/UserAvatar";

type BadgeMessageProps = {
  message: BadgeMessageSystem;
  isPreviewBadgeMessage?: boolean;
  setSelectedToEditMessage?: (v: BadgeMessageSystem) => void;
  setSelectedToDeleteMessage?: (v: number) => void;
};

const BadgeMessage: FC<BadgeMessageProps> = ({
  message,
  isPreviewBadgeMessage,
  setSelectedToEditMessage,
  setSelectedToDeleteMessage,
}) => {
  const { userId } = useParams();
  const location = useLocation();

  const { t } = useTranslation(["news", "common"]);
  const { t: tEvents } = useTranslation("events");
  const triggerRef = useRef<OverlayTriggerHandle | null>(null);
  const locale = useLocale();
  const currentUserId = useAppSelector((state) => state.auth.profile?.id);
  const isNewsPage = location.pathname === "/news";

  const {
    id,
    imageUrl,
    fromUser,
    message: badgeMessage,
    toUser,
    createdDate,
    isSystem,
    coins,
    badgeName,
    emojiReactions,
    autoEventType,
  } = message;

  const renderBadgeMessageMenu =
    !isSystem && !isPreviewBadgeMessage && currentUserId === fromUser.userId;
  const badgeUrl = imageUrl ? imageUrl : NoImageUrl;
  const dateTimeString = getDateTimeString(createdDate, "fullDateTime", locale);
  const toFullName = getFullName(toUser.firstName, toUser.familyName);
  const fromFullName = getFullName(fromUser.firstName, fromUser.familyName);
  const avatarSign = getAvatarSign(fromUser.firstName, fromUser.familyName);
  const { companyLogoImageUrl, companyName } =
    useAppSelector((state) => state.auth.profile) || {};
  const displayedCompanyName = companyName || "Esteeme";
  const getCongratulationsTitle = (): string => {
    if (
      autoEventType === AutoEventTypes.HireDate ||
      autoEventType === AutoEventTypes.BirthDate
    ) {
      return isPreviewBadgeMessage
        ? t(`congratulations${autoEventType}PreviewMsg`)
        : t(`congratulations${autoEventType}`, { userName: toFullName });
    }

    return t("congratulations");
  };

  const speaker = (
    <div className="customPopover badgeMessagePopover">
      <button
        type="button"
        onClick={() => {
          if (setSelectedToEditMessage) {
            setSelectedToEditMessage({
              ...message,
              message: safeJsonParse(badgeMessage),
            });
          }
          triggerRef.current?.close();
        }}
        className="customPopover__btn"
      >
        <Icon svgClass="btnIcon" href="#edite" />
        <p>{t("common:edit_btn")}</p>
      </button>

      <button
        type="button"
        onClick={() => {
          if (setSelectedToDeleteMessage) {
            setSelectedToDeleteMessage(id);
          }
          triggerRef.current?.close();
        }}
        className="customPopover__btn"
      >
        <Icon svgClass="btnIcon" href="#delet" />
        <p>{t("common:delete")}</p>
      </button>
    </div>
  );

  const companyLogo = () => {
    const companyLogoUrl = companyLogoImageUrl || "/img/logo.png";

    return (
      <div className="user-avatar companyLogo">
        <img src={companyLogoUrl} alt="company" />
      </div>
    );
  };

  return (
    <div className="box m-btm" key={id}>
      <div className="news-item">
        <div className="news-image-wrapper">
          <img
            src={badgeUrl}
            alt="badge"
            className={classNames("news-image", {
              "news-image-with-coins": coins,
            })}
          />
          {coins && (
            <div className="coins">
              + {tEvents("achievementCoins", { count: coins })}
            </div>
          )}
        </div>
        <div className="article">
          <div className="badgeMessageMenuWrap">
            <div className="user">
              {!isSystem ? (
                <UserAvatar
                  isOnline={fromUser.isOnline}
                  avatarImageUrl={fromUser.userAvatarUrl}
                  avatarSign={avatarSign}
                />
              ) : (
                companyLogo()
              )}

              <div className="userInfo">
                <p className="user-name">
                  {!isSystem ? (
                    <Link to={`/employee/${fromUser.userId}`}>
                      {fromFullName}
                    </Link>
                  ) : isNewsPage ? (
                    <span>{displayedCompanyName}</span>
                  ) : (
                    <Link to="/news">{displayedCompanyName}</Link>
                  )}
                </p>

                <p className="date-text">{dateTimeString}</p>
              </div>
            </div>

            {renderBadgeMessageMenu && (
              <Whisper
                speaker={speaker}
                ref={triggerRef}
                trigger="click"
                placement="bottomEnd"
              >
                <button
                  type="button"
                  className="btn-regular actionBtn-tertiary popoverBtn"
                >
                  <Icon href="#dots" svgClass="ic-dots" />
                </button>
              </Whisper>
            )}
          </div>

          <h2 className="title">
            {!isSystem ? badgeName : getCongratulationsTitle()}
          </h2>

          <div className="message">
            <ReactQuill
              readOnly={true}
              theme="bubble"
              value={
                isPreviewBadgeMessage
                  ? badgeMessage
                  : safeJsonParse(badgeMessage)
              }
            />
          </div>

          {!isPreviewBadgeMessage && (
            <p className="sender-text">
              {t("feedbackWasSendTo")}
              {(userId && +userId) === toUser.userId ? (
                <span className="sender-text--bold">{toFullName}</span>
              ) : (
                <Link
                  to={`/employee/${toUser.userId}`}
                  className="sender-text--bold"
                >
                  {toFullName}
                </Link>
              )}
            </p>
          )}
        </div>
      </div>
      {!isPreviewBadgeMessage && (
        <ReactionsBadgeMessage
          badgeMessageId={id}
          emojiReactions={emojiReactions}
        />
      )}
    </div>
  );
};

export default BadgeMessage;
