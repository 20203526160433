import classNames from "classnames";
import { FC, Fragment } from "react";
import { useTranslation } from "react-i18next";
import Loader, { LoaderSize } from "utils/components/Loader";
import { BadgesDataState } from "api/types/badgesTypes";
import { Tooltip, Whisper } from "rsuite";

type Props = {
  selectedBadgeId: number | null;
  changeSelectedBadgeId: (id: number | null) => void;
  badgeGroupsData: BadgesDataState;
};

const ModalChooseBadgeStep: FC<Props> = ({
  selectedBadgeId,
  changeSelectedBadgeId,
  badgeGroupsData,
}) => {
  const { t } = useTranslation("employeeAchievements");
  const { isLoading, badgeGroupsList, errorMessage } = badgeGroupsData;

  return (
    <div className="badge-wrapper">
      <div className="wrap">
        {isLoading && !badgeGroupsList && (
          <div className="loader-container">
            <Loader size={LoaderSize.Page} />
          </div>
        )}
        {errorMessage && (
          <div className="new-wrapper error">{errorMessage}</div>
        )}
        {badgeGroupsList && !badgeGroupsList.length && (
          <p className="no-content-message">{t("noBadgesToDisplay")}</p>
        )}
        {badgeGroupsList?.map((badgeGroup) => {
          return (
            <Fragment key={badgeGroup.groupName}>
              <h2 className="title-block m-btm">{badgeGroup.groupName}</h2>
              <div className="feedbackBadgesWrap">
                {badgeGroup.badges.map((badge) => (
                  <div
                    className={classNames("box feedbackBadge boxItemImg", {
                      active: badge.id === selectedBadgeId,
                    })}
                    key={badge.id}
                    onClick={() =>
                      changeSelectedBadgeId(
                        badge.id === selectedBadgeId ? null : badge.id
                      )
                    }
                  >
                    <Whisper
                      speaker={
                        <Tooltip className="custom-tooltip badgeTooltip">
                          {badge.name}
                        </Tooltip>
                      }
                      trigger="hover"
                      placement="topStart"
                      followCursor
                    >
                      <div className="boxItemImg__imgWrap d-flex">
                        <img src={badge.imageUrl} alt="badge_image" />

                        <div className="feedbackBadge__icon" />
                      </div>
                    </Whisper>
                  </div>
                ))}
              </div>
            </Fragment>
          );
        }) ?? null}
      </div>
    </div>
  );
};

export default ModalChooseBadgeStep;
