import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import {
  getEmployeeAchievementsData,
  getProfileMessages,
  refreshEmployeeAchievementsData,
} from "../../../store/thunks/employeePage/employeeAchievementsThunks";
import { resetProfileState } from "../../../store/reducers/employeePageReducers/employeeAchievementsReducer";
import Loader, { LoaderSize } from "../../../utils/components/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import BadgeMessage from "../../news/BadgeMessage";
import { useIsCurrentUser } from "../../../utils/hooks/useIsCurrentUser";
import FeedbackRequestsBlock from "./FeedbackRequestsBlock";
import { getErrorMessage } from "../../../utils/methods";
import { AxiosError } from "axios";
import { toastError } from "../../../utils/toasts";
import RequestFeedbackAnimation from "./RequestFeedbackAnimation";
import { UserOfSearch } from "../../../api/types/usersTypes";
import BadgesBlock from "./BadgesBlock";
import { BadgeMessageSystem } from "../../../api/types/news";
import { deleteBadgeMessage } from "../../../api/badgesApi";
import { toast } from "react-toastify";
import LayoutConfirmationPopup from "../../layout/LayoutConfirmationPopup";
import { handleRequestFeedback } from "../../../utils/methods/handleRequestFeedback";
import { Tooltip, Whisper } from "rsuite";
import SendCheersRightPanel from "./SendCheersRightPanel";

const EmployeeAchievementsPage = () => {
  const { t } = useTranslation("employeeAchievements");
  const { t: tCommon } = useTranslation("common");
  const { t: tNews } = useTranslation("news");

  const { userId } = useParams();
  const dispatch = useAppDispatch();
  const { isLoading, data, errorMessage, hasMoreData } = useAppSelector(
    (state) => state.employeeAchievements.profileMessages
  );
  const { data: userData } = useAppSelector(
    (state) => state.employeePage.userData
  );
  const unresponsedFeedbackRequestsData = useAppSelector(
    (state) => state.employeeAchievements.unresponsedFeedbackRequestsData
  );
  const isCurrentUser = useIsCurrentUser();
  const [isAnimationActive, setIsAnimationActive] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isEditBadgeMessageModalOpen, setIsEditBadgeMessageModalOpen] =
    useState(false);
  const [selectedBadgeMessage, setSelectedBadgeMessage] =
    useState<BadgeMessageSystem | null>(null);
  const [messageIdToDelete, setMessageIdToDelete] = useState<null | number>(
    null
  );
  const [isDeleteMessageModalOpen, setIsDeleteMessageModalOpen] =
    useState(false);

  const receiverInfo: UserOfSearch | null = userData
    ? {
        id: userData.id,
        avatarImageUrl: userData.avatarImageUrl,
        firstName: userData.firstName ?? "",
        familyName: userData.familyName ?? "",
        position: userData.position ?? "",
      }
    : null;
  const {
    data: senderFeedbackRequestsData,
    errorMessage: senderFeedbackRequestsError,
  } = unresponsedFeedbackRequestsData;

  const disabledFeedbackRequestButton = !!senderFeedbackRequestsData?.length;

  useEffect(() => {
    if (userId) {
      dispatch(getEmployeeAchievementsData(+userId));
    }

    return () => {
      dispatch(resetProfileState());
    };
  }, [userId]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async () => {
    if (userId) {
      await dispatch(getProfileMessages(+userId));
    }
  };

  const selectToEditBadgeMessage = (message: BadgeMessageSystem) => {
    setSelectedBadgeMessage(message);
    setIsEditBadgeMessageModalOpen(true);
  };

  const selectToDeleteBadgeMessage = (messageId: number) => {
    setMessageIdToDelete(messageId);
    setIsDeleteMessageModalOpen(true);
  };

  const handleDeleteAchievementBadgeMessage = async () => {
    if (messageIdToDelete && userId) {
      try {
        await deleteBadgeMessage(messageIdToDelete);
        toast.success(tNews("badgeMessage_deleted"));
        dispatch(refreshEmployeeAchievementsData(+userId));
      } catch (e) {
        const errorMessage = getErrorMessage(
          e as AxiosError,
          tNews("badgeMessage_deleteError") as string
        );
        toastError(errorMessage);
      } finally {
        setMessageIdToDelete(null);
        setIsDeleteMessageModalOpen(false);
      }
    } else return;
  };

  const addRequestFeedback = async () => {
    if (!userId || !userData?.id) return;

    await handleRequestFeedback(+userId, setIsAnimationActive);
    dispatch(getEmployeeAchievementsData(+userId));
  };

  return (
    <>
      <BadgesBlock />
      <div className="employeeAchievements">
        <div className="employeeAchievementsMsgs">
          <h1 className="main-title">
            {isCurrentUser ? t("achievements_user") : t("achievements_admin")}
          </h1>
          {isLoading && !data && (
            <div className="loader-container">
              <Loader size={LoaderSize.Small} />
            </div>
          )}
          {(errorMessage || senderFeedbackRequestsError) && (
            <div className="new-wrapper error">
              {errorMessage || senderFeedbackRequestsError}
            </div>
          )}
          {data && !data.length && (
            <p className="no-content-message">
              {isCurrentUser
                ? t("noMessagesToDisplay_user")
                : t("noMessagesToDisplay_admin")}
            </p>
          )}
          {data?.length ? (
            <InfiniteScroll
              dataLength={data.length}
              next={fetchData}
              hasMore={hasMoreData}
              loader={
                <div className="loader-container">
                  <Loader size={LoaderSize.Small} />
                </div>
              }
              endMessage={
                <p className="end-message">
                  {tCommon("infiniteScrollEndMessage")}
                </p>
              }
              style={{ overflow: "visible" }}
            >
              {data.map((message) => {
                return (
                  <BadgeMessage
                    message={message}
                    key={message.id}
                    setSelectedToEditMessage={selectToEditBadgeMessage}
                    setSelectedToDeleteMessage={selectToDeleteBadgeMessage}
                  />
                );
              })}
            </InfiniteScroll>
          ) : null}
        </div>

        <div>
          <div className="feedbacksBtns d-flex">
            {!isCurrentUser && (
              <Whisper
                speaker={
                  !disabledFeedbackRequestButton ? (
                    <div />
                  ) : (
                    <Tooltip className="custom-tooltip">
                      <span>{t("disabledTooltipFeedback")}</span>
                    </Tooltip>
                  )
                }
                trigger="hover"
                placement="topStart"
                followCursor
              >
                <div>
                  <button
                    className="btn-regular feedbacksBtns__feedback"
                    type="button"
                    onClick={addRequestFeedback}
                    disabled={disabledFeedbackRequestButton}
                  >
                    {t("action_requestFeedback")}
                  </button>
                </div>
              </Whisper>
            )}

            <button
              className="btn-primary feedbacksBtns__feedback"
              onClick={() => setIsModalOpen(true)}
            >
              {t("action_sendFeedback")}
            </button>

            <RequestFeedbackAnimation isAnimationActive={isAnimationActive} />
          </div>

          {isCurrentUser && <FeedbackRequestsBlock />}
        </div>
      </div>

      <SendCheersRightPanel
        isModalOpen={isEditBadgeMessageModalOpen}
        onCloseModal={() => {
          setSelectedBadgeMessage(null);
          setIsEditBadgeMessageModalOpen(false);
        }}
        badgeMessage={selectedBadgeMessage}
      />

      <LayoutConfirmationPopup
        isModalOpen={isDeleteMessageModalOpen}
        onCloseModal={() => {
          setMessageIdToDelete(null);
          setIsDeleteMessageModalOpen(false);
        }}
        confirmHandler={handleDeleteAchievementBadgeMessage}
        title={tNews("deleteBadgeMessage")}
        question={tNews("deleteBadgeMsgQuestion")}
      />

      <SendCheersRightPanel
        isModalOpen={isModalOpen}
        onCloseModal={() => setIsModalOpen(false)}
        receiver={!isCurrentUser ? receiverInfo : null}
      />
    </>
  );
};

export default EmployeeAchievementsPage;
