import { Field } from "formik";
import CustomSelect from "../CustomSelect";
import { CountrySingleValue, CustomCountryOption } from "./CustomCountrySelect";
import { FC, useState } from "react";
import { getCountriesOptions } from "./utils";
import { useTranslation } from "react-i18next";
import Icon from "../Icon";

type UserCountrySelect = {
  isEditDisabled: boolean;
  isCountryCorrect: boolean;
};

const UserCountrySelect: FC<UserCountrySelect> = ({
  isEditDisabled,
  isCountryCorrect,
}) => {
  const { t } = useTranslation("accountSettings");
  const countriesList = getCountriesOptions();
  const [isShowWarn, setIsShowWarn] = useState(!isCountryCorrect);

  if (isEditDisabled) {
    return (
      <Field
        id="country"
        type="text"
        name="country"
        placeholder={t("userForm_country")}
        className="form-input"
        disabled={isEditDisabled}
      />
    );
  }

  return (
    <>
      <Field
        id="country"
        component={CustomSelect}
        name="country"
        customComponents={{
          Option: CustomCountryOption,
          SingleValue: CountrySingleValue,
        }}
        handleChange={() => setIsShowWarn(false)}
        options={countriesList}
        placeholder={t("userForm_country")}
        className="form-input"
        isSearchable
        isClearable
      />
      {isShowWarn && (
        <div className="d-flex">
          <Icon href="#warning" svgClass="userCountryWarn" />

          <p className="userCountryWarn__text">{t("incorrectCountryWarn")}</p>
        </div>
      )}
    </>
  );
};

export default UserCountrySelect;
