import isNumber from "lodash/isNumber";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store";
import { getReceiverCountry, Icon } from "utils/components";
import { useIsCurrentUser } from "utils/hooks/useIsCurrentUser";
import { getUserDetails } from "utils/methods";
import { useEmployeeCardViewsPermissions } from "../hooks/useEmployeeCardViewsPermissions";
import { getEmploymentTypeNameLocalized } from "../utils/getEmploymentTypeNameLocalized";

type BasicInfoProps = {
  isShowPronouns?: boolean;
};

const BasicInfoList: FC<BasicInfoProps> = ({ isShowPronouns }) => {
  const { t } = useTranslation(["accountSettings", "countries"]);
  const { t: tEmployeePage } = useTranslation("employeePage");
  const { data } = useAppSelector((state) => state.employeePage.employeeData);
  const currentUser = useIsCurrentUser();
  const permissions = useEmployeeCardViewsPermissions();
  const {
    firstName,
    familyName,
    position,
    departament,
    manager,
    companyName,
    employmentType,
    country,
    location,
    pronouns,
  } = data?.basicInfo || {};
  const isCorrectCountryValue = Boolean(getReceiverCountry(country));

  if (!data) {
    return null;
  }

  return (
    <>
      <p className="employeePage__label">{t("userForm_id")}</p>

      <p className="employeePage__value">
        {(currentUser || permissions.isViewEmployeeId) &&
        isNumber(data.employeeId) ? (
          data.employeeId
        ) : (
          <Icon href="#lock" svgClass="ic-lock" />
        )}
      </p>

      <p className="employeePage__label">{t("userForm_firstName")}</p>
      <p className="employeePage__value">{firstName}</p>

      <p className="employeePage__label">{t("userForm_familyName")}</p>
      <p className="employeePage__value">{familyName}</p>

      <p className="employeePage__label">{t("userForm_position")}</p>
      <p className="employeePage__value">{position}</p>

      <p className="employeePage__label">{t("userForm_departament")}</p>
      <p className="employeePage__value">{departament}</p>

      <p className="employeePage__label">{t("userForm_manager")}</p>
      <p className="employeePage__value">
        {manager ? getUserDetails(manager).displayedFullName : ""}
      </p>

      <p className="employeePage__label">{t("userForm_company")}</p>
      <p className="employeePage__value">{companyName}</p>

      <p className="employeePage__label">
        {tEmployeePage("basicInfo_employmentType")}
      </p>
      <p className="employeePage__value">
        {currentUser || permissions.isViewEmployeeEmploymentType ? (
          getEmploymentTypeNameLocalized(tEmployeePage, employmentType || null)
        ) : (
          <Icon href="#lock" svgClass="ic-lock" />
        )}
      </p>

      <p className="employeePage__label">{t("userForm_country")}</p>
      <p className="employeePage__value">
        {isCorrectCountryValue ? t(`countries:${country}`) : country}
      </p>

      <p className="employeePage__label">{t("userForm_location")}</p>
      <p className="employeePage__value">
        {currentUser || permissions.isViewEmployeeLocation ? (
          location
        ) : (
          <Icon href="#lock" svgClass="ic-lock" />
        )}
      </p>

      {isShowPronouns && (
        <>
          <p className="employeePage__label">{t("userForm_pronouns")}</p>
          <p className="employeePage__value">{pronouns}</p>
        </>
      )}
    </>
  );
};

export default BasicInfoList;
