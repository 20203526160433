import { useState } from "react";
import { useTranslation } from "react-i18next";
import CropImageModal from "./cropImageModal/CropImageModal";
import Icon from "./Icon";
import classnames from "classnames";

type CoverImageProps = {
  uploadedImgPreview: string;
  saveUploadedImage: (croppedImage: string) => void;
  isLabelEditBtn?: boolean;
  isEditBtn: boolean;
  statusLabel?: {
    label: string;
    className: string;
  } | null;
  aspectRatio?: number; // define crop box sizes
};

const CoverImage: React.FC<CoverImageProps> = ({
  uploadedImgPreview,
  saveUploadedImage,
  statusLabel,
  isLabelEditBtn,
  isEditBtn,
  aspectRatio,
}) => {
  const { t } = useTranslation("events");
  const { t: tCommon } = useTranslation("common");
  const [isCropModalOpen, setIsCropModalOpen] = useState(false);
  const toggleCropModal = () => {
    setIsCropModalOpen((prev) => !prev);
  };

  const renderLabel = () => {
    if (!statusLabel) return null;
    const { label, className } = statusLabel;
    return <div className={`${className} achievementStatusLabel`}>{label}</div>;
  };

  const renderEditButton = () => {
    if (!isEditBtn) return null;
    return (
      <button
        type="button"
        className="btn-regular actionBtn-regular editCoverBtn"
        onClick={toggleCropModal}
      >
        <Icon svgClass="buttonIcon" href="#edite" />
        {isLabelEditBtn && (
          <span className="edit-image-text">{tCommon("uploadImage")}</span>
        )}
      </button>
    );
  };

  return (
    <div
      className={classnames("coverImageWrap", {
        "coverImageWrap--border": !uploadedImgPreview,
      })}
    >
      <img
        src={uploadedImgPreview || "/img/defaultCoverAddEvent.png"}
        alt="eventImg"
        className="coverImage"
      />
      {renderLabel()}
      {renderEditButton()}

      <CropImageModal
        isOpen={isCropModalOpen}
        onClose={toggleCropModal}
        onSubmit={saveUploadedImage}
        aspectRatio={aspectRatio}
      />
    </div>
  );
};

export default CoverImage;
