import { IntegrationTypes, UserSources } from "utils/consts";
import { useCompanyIntegrationsCheck } from "./useCompanyIntegrationsCheck";

export const useDisableEditDeleteUser = (userSource?: string | null) => {
  const integrationSourceMap = [
    {
      userSource: UserSources.MicrosoftEntraId,
      isIntegrationActive: useCompanyIntegrationsCheck([
        IntegrationTypes.MicrosoftEntraId,
      ]),
    },
    {
      userSource: UserSources.PeopleForce,
      isIntegrationActive: useCompanyIntegrationsCheck([
        IntegrationTypes.PeopleForce,
      ]),
    },
    {
      userSource: UserSources.BambooHR,
      isIntegrationActive: useCompanyIntegrationsCheck([
        IntegrationTypes.BambooHR,
      ]),
    },
  ];

  const checkUserSource = integrationSourceMap.find(
    (el) => el.userSource === userSource
  );

  if (checkUserSource && checkUserSource.isIntegrationActive) {
    return true;
  }

  return false;
};
