import { getCoinsComplete } from "pages/events/achievement/methods/getCoinsComplete";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Icon } from "utils/components";
import { AchievementDto } from "../../../api/types/achievementTypes";
import useLocale from "../../../localization/useLocale";
import { getAchievementTimeline } from "../../events/achievement/methods/getAchievementTimeline";

type EssentialAchievementCardProps = {
  achievement: AchievementDto;
};

const EssentialAchievementCard: FC<EssentialAchievementCardProps> = ({
  achievement,
}) => {
  const { t } = useTranslation("events");
  const locale = useLocale();
  const {
    id,
    name,
    coins,
    startDate,
    activeUntil,
    previewImageUrl,
    filterStatus,
  } = achievement;
  const coinsComplete = getCoinsComplete(filterStatus);

  return (
    <Link to={`/achievements/${id}`} className="box essentialCard">
      <img src={previewImageUrl} alt="event" className="essentialCard__img" />

      <div className="essentialCard__info">
        <p className="essentialCard__name">{name}</p>

        <div className="d-flex essentialCard__labelsWrap">
          <div
            className={`achievementCard__label achievementCard__coins d-flex ${coinsComplete?.className}`}
          >
            {coinsComplete?.renderCompleteIcon ? (
              <>
                <Icon href="#checkmark" svgClass="ic-checkmark" />
                <Icon href="#coins" svgClass="ic-coins" />
                <span>{t("achievementCoins", { count: coins })}</span>
              </>
            ) : (
              <span>{`+ ${t("achievementCoins", { count: coins })}`}</span>
            )}
          </div>

          <div className="achievementCard__label">
            {getAchievementTimeline(locale, startDate, activeUntil)}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default EssentialAchievementCard;
