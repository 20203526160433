import { ErrorMessage, Field, useFormikContext } from "formik";
import isNumber from "lodash/isNumber";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Visibility } from "../../../api/types/badgesTypes";
import { UserOfSearch } from "../../../api/types/usersTypes";
import { NoImageUrl } from "../../../appConsts";
import AggregateErrorMessage from "../../../utils/components/AggregateErrorMessage";
import CustomSelect from "../../../utils/components/CustomSelect";
import { Features } from "../../../utils/consts";
import { getFullName, getFullNameFromUser } from "../../../utils/methods";
import { Feedback } from "../../../api/types/employeePageTypes";
import { BadgeMessageSystem } from "../../../api/types/news";
import CustomRichTextComponent from "utils/components/CustomRichTextComponent";
import SearchUsersSelect from "../../../utils/components/SearchUsersSelect";
import { useCompanyFeatureCheck } from "../../../utils/hooks";
import CustomInputNumber from "../../../utils/components/CustomInputNumber";
import RequiredFieldLabel from "../../../utils/components/RequiredFieldLabel";
import { useAppSelector } from "store";
import {
  MAX_LENGTH_CHEERS_MSG,
  SendCheersValues,
} from "./SendCheersRightPanel";

type SendCheersFormProps = {
  onComeBack: (i: number) => void;
  selectedBadgeImage: string | undefined;
  receiver?: UserOfSearch | null;
  feedback?: Feedback | null;
  badgeMessage?: BadgeMessageSystem | null;
};

const SendCheersModalFormStep: FC<SendCheersFormProps> = ({
  onComeBack,
  selectedBadgeImage,
  receiver,
  feedback,
  badgeMessage,
}) => {
  const { t } = useTranslation("employeeAchievements");
  const { t: tCommon } = useTranslation("common");
  const isGamificationAvailable = useCompanyFeatureCheck(Features.Gamification);
  const { data: coinsBalance } = useAppSelector(
    (state) => state.auth.coinsCount
  );

  const badgeImg = selectedBadgeImage ? selectedBadgeImage : NoImageUrl;

  const { values } = useFormikContext<SendCheersValues>();

  const gerReceiverFullName = () => {
    if (receiver) {
      return getFullNameFromUser(receiver);
    }

    if (feedback) {
      return getFullName(
        feedback.fromUser?.firstName,
        feedback.fromUser?.familyName
      );
    }

    if (badgeMessage) {
      return getFullName(
        badgeMessage.toUser.firstName,
        badgeMessage.toUser.familyName
      );
    }

    return null;
  };

  const visibilityOptions = [
    { value: Visibility.all, label: t("feedbackVisibility_all") },
    { value: Visibility.me, label: t("feedbackVisibility_me") },
  ];

  const totalSentCoins = Number(values.coins) * values.receiverUserIds.length;
  const isAlreadyChosenReceiver = receiver || feedback || badgeMessage;

  return (
    <div className="popup-feedback-next form-style">
      <div className="new-wrap">
        <div className="wrap-item">
          <div className="box boxItemImg">
            <div className="boxItemImg__imgWrap d-flex">
              <img src={badgeImg} alt="badge" />
            </div>
          </div>

          {!badgeMessage && (
            <button
              type="button"
              className="btn-regular"
              onClick={() => onComeBack(0)}
            >
              {t("chooseAnotherBadge")}
            </button>
          )}
        </div>
        <ErrorMessage
          component="div"
          name="badgeId"
          className="new-wrapper error"
        />
        <AggregateErrorMessage component="div" className="new-wrapper error" />

        <label className="label">
          <RequiredFieldLabel labelTitle={t("sendMessage_to")} />

          {isAlreadyChosenReceiver ? (
            <>
              <div className="receiverName">{gerReceiverFullName()}</div>
              <Field type="hidden" name="receiverUserIds" />
            </>
          ) : (
            <Field
              name="receiverUserIds"
              component={SearchUsersSelect}
              filterCurrentUser={true}
            />
          )}
          <ErrorMessage
            component="div"
            name="receiverUserIds"
            className="error m-btm"
          />
        </label>

        {!badgeMessage && (
          <>
            <label className="label">
              <span className="label-title">{tCommon("visibility")}</span>
              <Field
                name="visibility"
                component={CustomSelect}
                options={visibilityOptions}
                placeholder={tCommon("select_placeholder")}
              />
              <ErrorMessage
                component="div"
                name="visibility"
                className="error"
              />
            </label>

            {isGamificationAvailable && isNumber(coinsBalance) && (
              <>
                <label className="label">
                  <span className="label-title">{t("sendCoins_label")}</span>
                  <Field
                    name="coins"
                    component={CustomInputNumber}
                    placeholder={t("sendCoins_placeholder")}
                  />
                  <ErrorMessage
                    component="div"
                    name="coins"
                    className="error"
                  />
                </label>

                {values.coins ? (
                  <div className="totalSpentCoins">
                    <span>{t("sendCoins_total")}</span>
                    <span className="totalCount">{`${Number(values.coins)} X ${
                      values.receiverUserIds.length
                    } = ${totalSentCoins}`}</span>
                  </div>
                ) : null}
              </>
            )}
          </>
        )}

        <div className="label">
          <RequiredFieldLabel labelTitle={t("sendMessage_message")} />

          <Field
            name="message"
            component={CustomRichTextComponent}
            rows={6}
            placeholder={
              tCommon("message_message_placeholder", {
                count: MAX_LENGTH_CHEERS_MSG,
              }) as string
            }
            maxLength={MAX_LENGTH_CHEERS_MSG}
          />
        </div>
      </div>
    </div>
  );
};

export default SendCheersModalFormStep;
