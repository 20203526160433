import { UserOfSearchWithActiveStatus } from "./usersTypes";

export enum SearchLeadersMode {
  allCompany = 0,
  myTeam = 1,
}

export type LeaderDto = UserOfSearchWithActiveStatus & {
  rating: number;
  coins: number;
};

export type SearchLeadersFilters = {
  mode: SearchLeadersMode;
};

export type SearchLeadersRequest = SearchLeadersFilters & {
  pageNumber: number;
  pageSize: number;
};

export type SearchLeadersResponse = {
  items: LeaderDto[];
  pageNumber: number;
  totalItems: number;
};
