import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getAvatarSign, getErrorMessage, getFullName } from "utils/methods";
import { useAppDispatch, useAppSelector } from "../../../store";
import Loader, { LoaderSize } from "../../../utils/components/Loader";
import HasPermissions from "../../../auth/HasPermissions";
import { AppPermissions } from "../../../utils/consts";
import Icon from "../../../utils/components/Icon";
import { Whisper } from "rsuite";
import classNames from "classnames";
import { Feedback } from "../../../api/types/employeePageTypes";
import { toastError, toastSuccess } from "../../../utils/toasts";
import { AxiosError } from "axios";
import { refreshFeedbackRequests } from "../../../store/thunks/employeePage/employeeAchievementsThunks";
import { deleteFeedbackRequest } from "../../../api/employeePageApi";
import LayoutConfirmationPopup from "../../layout/LayoutConfirmationPopup";
import UserAvatar from "../../../utils/components/UserAvatar";
import SendCheersRightPanel from "./SendCheersRightPanel";


type FeedbackSpeakerProps = {
  feedback: Feedback;
  onClose: () => void;
};

const FeedbackRequestsBlock = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("employeeAchievements");
  const { t: tOrgChart } = useTranslation("orgChart");
  const { t: tCommon } = useTranslation("common");

  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const feedbackRequestsData = useAppSelector(
    (state) => state.employeeAchievements.feedbackRequestsData
  );
  const { isLoading, data, errorMessage } = feedbackRequestsData;
  const [feedbackInfo, setFeedbackInfo] = useState<null | Feedback>(null);
  const [selectedFeedbackId, setSelectedFeedbackId] = useState<null | number>(
    null
  );

  const [requestIdToDelete, setRequestIdToDelete] = useState<number | null>(
    null
  );
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleDeleteFeedbackRequest = async () => {
    if (requestIdToDelete) {
      try {
        await deleteFeedbackRequest(requestIdToDelete);
        dispatch(refreshFeedbackRequests());
        toastSuccess(t("feedbackRequest_deleted"));
      } catch (e) {
        const errorMessage = getErrorMessage(
          e as AxiosError,
          t("feedbackRequest_deleteError") as string
        );
        toastError(errorMessage);
      } finally {
        setRequestIdToDelete(null);
        setIsDeleteModalOpen(false);
      }
    } else return;
  };

  const Speaker = React.forwardRef<HTMLDivElement, FeedbackSpeakerProps>(
    (props, ref) => {
      const { feedback, onClose } = props;
      const userId = feedback.fromUser.id;

      return (
        <div
          className="customPopover achievementParticipants__popover"
          ref={ref}
        >
          <button
            type="button"
            className="customPopover__btn"
            onClick={() => {
              onClose();
              setFeedbackInfo(feedback);
              setIsModalOpen(true);
              setSelectedFeedbackId(null);
            }}
          >
            <Icon svgClass="btnIcon" href="#BadgeMessageIcon" />
            <p>{t("sendFeedbackTitle")}</p>
          </button>

          <button
            type="button"
            className="customPopover__btn"
            onClick={() => {
              onClose();
              setRequestIdToDelete(feedback.id);
              setIsDeleteModalOpen(true);
            }}
          >
            <Icon svgClass="btnIcon" href="#deleteRequest" />
            <p>{t("deleteRequest_title")}</p>
          </button>

          <HasPermissions
            requiredPermissions={[AppPermissions.ViewCompanyOrgStructure]}
          >
            <button
              type="button"
              className="customPopover__btn"
              onClick={() => navigate(`/org-structure/${userId}`)}
            >
              <Icon svgClass="btnIcon" href="#orgChart" />
              <p>{tOrgChart("seeAtOrgChart")}</p>
            </button>
          </HasPermissions>

          <HasPermissions
            requiredPermissions={[
              AppPermissions.ViewAndEditUserEmployeeCards,
              AppPermissions.EmployeeCard_ViewUsersEmployeeCard,
            ]}
          >
            <button
              type="button"
              className="customPopover__btn"
              onClick={() => navigate(`/employee/${userId}`)}
            >
              <Icon svgClass="btnIcon" href="#user" />
              <p>{tCommon("viewProfile")}</p>
            </button>
          </HasPermissions>
        </div>
      );
    }
  );

  return (
    <>
      <div className="feedbackRequests">
        <h2 className="main-title">{t("feedbackRequestsToMe")}</h2>
        {isLoading && (
          <div className="loader-container">
            <Loader size={LoaderSize.Small} />
          </div>
        )}
        {errorMessage && <div className="error">{errorMessage}</div>}
        {data && !data.length && (
          <p className="no-content-message">{t("noFeedbacksMsg")}</p>
        )}
        {data?.map((feedback) => {
          const {
            avatarImageUrl,
            familyName,
            firstName,
            position,
            isOnline,
          } = feedback.fromUser;

          return (
            <div
              className={classNames("box user-card d-flex", {
                active: selectedFeedbackId === feedback.id,
              })}
              key={feedback.id}
            >
              <div className="user-info">
                <UserAvatar
                  isOnline={isOnline}
                  avatarImageUrl={avatarImageUrl}
                  avatarSign={getAvatarSign(firstName, familyName)}
                />

                <div>
                  <p className="user-name">
                    {getFullName(firstName, familyName)}
                  </p>
                  <p className="user-additionalInfo">{position}</p>
                </div>
              </div>

              <Whisper
                speaker={(props, ref) => (
                  <Speaker {...props} ref={ref} feedback={feedback} />
                )}
                trigger="click"
                placement="bottomEnd"
                onClose={() => setSelectedFeedbackId(null)}
              >
                <button
                  type="button"
                  className="btn-regular actionBtn-tertiary"
                  onClick={() => setSelectedFeedbackId(feedback.id)}
                >
                  <Icon href="#dots" svgClass="ic-dots" />
                </button>
              </Whisper>
            </div>
          );
        })}
      </div>
      <SendCheersRightPanel
        isModalOpen={isModalOpen}
        onCloseModal={() => {
          setFeedbackInfo(null);
          setIsModalOpen(false);
        }}
        feedback={feedbackInfo}
      />
      <LayoutConfirmationPopup
        isModalOpen={isDeleteModalOpen}
        onCloseModal={() => {
          setRequestIdToDelete(null);
          setIsDeleteModalOpen(false);
        }}
        confirmHandler={handleDeleteFeedbackRequest}
        title={t("deleteRequest_title")}
        question={t("feedbackRequest_deleteQuestion")}
      />
    </>
  );
};

export default FeedbackRequestsBlock;
