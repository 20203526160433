import { FieldProps } from "formik";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { DatePicker } from "rsuite";
import { Placement } from "../../api/types/commonTypes";
import { DatePickersCustomIcon } from "./DatePickersCustomIcon";

interface CustomDatePickerProps extends FieldProps {
  placement?: Placement;
  disabledDate?: (date: Date) => boolean;
  disabled?: boolean;
  cleanable?: boolean;
  onChangeAction?: () => void;
}
export const CustomDatePicker: FC<CustomDatePickerProps> = ({
  field,
  form,
  placement,
  disabledDate = () => false,
  disabled = false,
  cleanable = true,
  onChangeAction,
}) => {
  const { t } = useTranslation("common");

  const onDateChange = (date: Date | null) => {
    form.setFieldValue(field.name, date);

    if (onChangeAction) {
      onChangeAction();
    }
  };

  return (
    <DatePicker
      name={field.name}
      value={field.value}
      onChange={onDateChange}
      className="date-picker"
      size="lg"
      placeholder={t("datePickerPlaceholder")}
      format="MM/dd/yyyy"
      oneTap
      shouldDisableDate={(date) => disabledDate(date) && !disabled}
      placement={placement || "bottomEnd"}
      caretAs={disabled ? null : DatePickersCustomIcon}
      disabled={disabled}
      cleanable={disabled ? false : cleanable}
      onBlur={field.onBlur(field.name)}
    />
  );
};

export default CustomDatePicker;
