import { BadgesDataState, Visibility } from "api/types/badgesTypes";
import { Feedback } from "api/types/employeePageTypes";
import { BadgeMessageSystem } from "api/types/news";
import { UserOfSearch } from "api/types/usersTypes";
import {
  Formik,
  Form,
  FormikProps,
  FormikHelpers,
  FormikState,
  FormikErrors,
} from "formik";
import * as Yup from "yup";
import LayoutRightPanel from "pages/layout/LayoutRightPanel";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store";
import { refreshCoinsCount } from "store/thunks/authThunks";
import { Features } from "utils/consts";
import { useCompanyFeatureCheck } from "utils/hooks";
import { getFullName, handleServerError } from "utils/methods";
import { fetchBadges } from "utils/methods/fetchBadges";
import { OptionNumberValue, ValidationSchemas } from "utils/types";
import { DeltaStatic } from "quill";
import { respondToRequestFeedback } from "api/employeePageApi";
import { editBadgeMessage, sendBadgeMessage } from "api/badgesApi";
import { editNewsBadgeMessage } from "store/reducers/newsReducer";
import { editProfileBadgeMessage } from "store/reducers/employeePageReducers/employeeAchievementsReducer";
import { toastError, toastSuccess } from "utils/toasts";
import { refreshEmployeeAchievementsData } from "store/thunks/employeePage/employeeAchievementsThunks";
import { AxiosError } from "axios";
import { serverError } from "types/serverError";
import { useLocation, useParams } from "react-router-dom";
import { ButtonLoader } from "utils/components";
import ModalChooseBadgeStep from "utils/components/ModalChooseBadgeStep";
import SendCheersModalFormStep from "./SendCheersModalFormStep";

export const MAX_LENGTH_CHEERS_MSG = 340;
const sendCheersFirstStep = 0;
const sendCheersLastStep = 1;

type SendCheersProps = {
  isModalOpen: boolean;
  onCloseModal: () => void;
  receiver?: UserOfSearch | null;
  feedback?: Feedback | null;
  badgeMessage?: BadgeMessageSystem | null;
};

export type SendCheersValues = {
  receiverUserIds: OptionNumberValue[];
  message: string;
  badgeId: number | null;
  visibility: Visibility;
  coins: number | "";
};

const initialBadgeData = {
  isLoading: false,
  badgeGroupsList: null,
  errorMessage: "",
};

const SendCheersRightPanel: FC<SendCheersProps> = ({
  isModalOpen,
  onCloseModal,
  receiver,
  feedback,
  badgeMessage,
}) => {
  const { t } = useTranslation("employeeAchievements");
  const { t: tCommon } = useTranslation("common");
  const formikRef = useRef<FormikProps<SendCheersValues>>(null);
  const location = useLocation();
  const isHomePage = location.pathname.includes("news");
  const dispatch = useAppDispatch();
  const { data: coinsBalance } = useAppSelector(
    (state) => state.auth.coinsCount
  );

  const isGamificationAvailable = useCompanyFeatureCheck(Features.Gamification);
  const [badgeGroupsData, setBadgeGroupsData] =
    useState<BadgesDataState>(initialBadgeData);
  const [step, setStep] = useState(sendCheersFirstStep);

  const { userId } = useParams();

  // we store as receiverUserIds array of object {value: userId, label: userFullName } for reusing existing SearchUserSelect.tsx
  // async select in this component is used in editing mode also, so its value must be array of objects {label: string, value: number | string} not just number
  // but in request to api we send only userIds
  const getReceiverUserIds = () => {
    if (receiver) {
      return [
        {
          value: receiver.id,
          label: getFullName(receiver.firstName, receiver.familyName),
        },
      ];
    }

    if (feedback?.fromUser) {
      return [
        {
          value: feedback.fromUser.id,
          label: getFullName(
            feedback?.fromUser.firstName,
            feedback?.fromUser.familyName
          ),
        },
      ];
    }

    if (badgeMessage?.toUser.userId) {
      return [
        {
          value: badgeMessage.toUser.userId,
          label: getFullName(
            badgeMessage?.toUser.firstName,
            badgeMessage?.toUser.familyName
          ),
        },
      ];
    }

    return [];
  };

  const initialValues: SendCheersValues = {
    receiverUserIds: getReceiverUserIds(),
    message: badgeMessage ? badgeMessage.message : "",
    badgeId: badgeMessage ? badgeMessage.badgeId : null,
    visibility: Visibility.all,
    coins: "",
  };

  useEffect(() => {
    if (isModalOpen) {
      fetchBadges(setBadgeGroupsData);
    }

    if (isGamificationAvailable) {
      dispatch(refreshCoinsCount());
    }

    if (badgeMessage) {
      setStep(sendCheersLastStep);
    }
  }, [isModalOpen]);

  const renderModalTitle = () => {
    if (!step) {
      return t("chooseBadgeTitle");
    }

    if (badgeMessage) {
      return t("action_editCheers");
    }

    return t("sendFeedbackTitle");
  };

  const validationSchemas: ValidationSchemas = {
    0: Yup.object().shape({
      badgeId: Yup.number().required(t("message_badge_required") as string),
    }),
    1: Yup.object().shape({
      receiverUserIds: Yup.array().min(
        1,
        t("message_receivers_required") as string
      ),
      message: Yup.mixed()
        .required(t("message_message_required") as string)
        .test(
          "lengthCheck",
          tCommon("lengthDesc_error") as string,
          (value: DeltaStatic) => {
            if (value) {
              const length = value.length() - 1;
              if (length > MAX_LENGTH_CHEERS_MSG) return false;
            }
            return true;
          }
        ),
      visibility: Yup.string(),
      coins: Yup.number()
        .nullable()
        .positive(tCommon("numberValue_positive") as string)
        .integer(tCommon("numberValue_integer") as string)
        .test(
          "totalCoinsCheck",
          t("coinsBalance_err") as string,
          (value, ctx) => {
            if (value && coinsBalance) {
              const totalSpentCount = value * ctx.parent.receiverUserIds.length;
              if (totalSpentCount > coinsBalance || value > coinsBalance)
                return false;
            }

            return true;
          }
        ),
    }),
  };

  const sendCheers = async (
    values: SendCheersValues,
    setErrors: (errors: FormikErrors<SendCheersValues>) => void,
    resetForm: (
      nextState?: Partial<FormikState<SendCheersValues>> | undefined
    ) => void
  ) => {
    const baseData = {
      badgeId: values.badgeId,
      message: JSON.stringify(values.message),
      visibility: values.visibility,
      coins: values.coins || null,
    };

    const data = {
      ...baseData,
      receiverUserIds: values.receiverUserIds.map((user) => user.value),
    };

    try {
      if (feedback) {
        // respond to feedback request
        await respondToRequestFeedback(baseData, feedback.id);
      } else if (badgeMessage) {
        // editing badge message
        const response = await editBadgeMessage(
          badgeMessage.id,
          JSON.stringify(values.message)
        );

        if (isHomePage) {
          dispatch(editNewsBadgeMessage(response.data.message));
        } else {
          dispatch(editProfileBadgeMessage(response.data.message));
        }
      } else {
        // sending badge message
        await sendBadgeMessage(data);
      }
      toastSuccess(
        badgeMessage
          ? t("messageSuccessfullyUpdated")
          : t("messageSuccessfullySent")
      );

      if (isGamificationAvailable && values.coins) {
        dispatch(refreshCoinsCount());
      }

      // update employee achievements data if send cheers or respond to feedback request
      if (userId && !badgeMessage) {
        dispatch(refreshEmployeeAchievementsData(+userId));
      }

      resetForm();
      onCloseModal();
    } catch (e) {
      const axiosError = e as AxiosError;
      const error = axiosError.response?.data as serverError;
      toastError(error?.message || tCommon("incorrectData"));
      setErrors(handleServerError(error || {}));
    }
  };

  const handleSubmitStep = async (
    values: SendCheersValues,
    { setErrors, resetForm, setTouched }: FormikHelpers<SendCheersValues>
  ) => {
    if (step === sendCheersLastStep) {
      await sendCheers(values, setErrors, resetForm);
    } else {
      onChangeStep(sendCheersLastStep);
    }
    if (step === sendCheersFirstStep) {
      setTouched({});
    }
  };

  const submitBtnLabel = badgeMessage
    ? tCommon("form_save")
    : t("action_sendMessage");

  const closeModal = () => {
    onCloseModal();
    setStep(sendCheersFirstStep);
    setBadgeGroupsData({
      isLoading: false,
      badgeGroupsList: null,
      errorMessage: "",
    });
    formikRef?.current?.resetForm();
    formikRef?.current?.setErrors({});
  };

  const onChangeStep = (index: number) => {
    setStep(index);
  };

  return (
    <LayoutRightPanel
      isModalOpen={isModalOpen}
      onCloseModal={closeModal}
      title={renderModalTitle()}
      additionalClassName={"popup-feedback popup-badge"}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchemas[step]}
        onSubmit={handleSubmitStep}
        innerRef={formikRef}
        enableReinitialize={true}
      >
        {(props: FormikProps<SendCheersValues>) => {
          const { values, setFieldValue, isSubmitting } = props;

          const badgeImage = badgeGroupsData?.badgeGroupsList
            ?.flatMap((x) => x.badges)
            ?.find((badge) => badge.id === values.badgeId)?.imageUrl;

          const isDisabledNextStep =
            Boolean(badgeGroupsData.errorMessage) ||
            !badgeGroupsData.badgeGroupsList?.length ||
            !values.badgeId;

          const isSubmitBtnDisabled = !step ? isDisabledNextStep : isSubmitting;

          const changeSelectedBadgeId = (id: number | null) => {
            setFieldValue("badgeId", id);
          };

          return (
            <Form
              className="form-feedback"
              autoComplete="off"
              noValidate={true}
            >
              {!step ? (
                <ModalChooseBadgeStep
                  selectedBadgeId={values.badgeId}
                  changeSelectedBadgeId={changeSelectedBadgeId}
                  badgeGroupsData={badgeGroupsData}
                />
              ) : (
                <SendCheersModalFormStep
                  onComeBack={onChangeStep}
                  selectedBadgeImage={badgeImage}
                  receiver={receiver}
                  feedback={feedback}
                  badgeMessage={badgeMessage}
                />
              )}

              <div className="popup-footer">
                <div className="group-btn d-flex">
                  <button
                    className="btn-tertiary main-close"
                    type="button"
                    onClick={closeModal}
                  >
                    {tCommon("form_cancel")}
                  </button>

                  <button
                    className="btn-primary btn-popup"
                    type="submit"
                    disabled={isSubmitBtnDisabled}
                  >
                    {!step ? (
                      tCommon("form_next")
                    ) : isSubmitting ? (
                      <ButtonLoader />
                    ) : (
                      submitBtnLabel
                    )}
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </LayoutRightPanel>
  );
};

export default SendCheersRightPanel;
