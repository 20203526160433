import { editBasicEmployeeInfo } from "api/employeePageApi";
import {
  EditBasicInfoRequest,
  EmploymentTypes,
} from "api/types/employeePageTypes";
import { AxiosError } from "axios";
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
} from "formik";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";
import { useAppDispatch, useAppSelector } from "store";
import {
  getBasicInfoFormData,
  getUserData,
  refreshEmployeePageData,
} from "store/thunks/employeePage/employeePageThunks";
import { serverError } from "types/serverError";
import {
  AggregateErrorMessage,
  ButtonLoader,
  CustomSelect,
  getReceiverCountry,
  Loader,
  LoaderSize,
} from "utils/components";
import { AppPermissions } from "utils/consts";
import {
  disableUserFieldForEdit,
  handleServerError,
  searchUsersOptions,
} from "utils/methods";
import { toastError, toastSuccess } from "utils/toasts";
import * as Yup from "yup";
import { usePermissionToEmployeeCard } from "../hooks/usePermissionToEmployeeCard";
import BasicInfoList from "./BasicInfoList";
import classnames from "classnames";
import { getUserInfo } from "../../../../store/thunks/authThunks";
import { useIsCurrentUser } from "../../../../utils/hooks/useIsCurrentUser";
import { usePermissionCheck } from "../../../../utils/hooks";
import RequiredFieldLabel from "../../../../utils/components/RequiredFieldLabel";
import { useDisableEditDeleteUser } from "utils/hooks/useDisableEditDeleteUser";
import UserCountrySelect from "utils/components/customCountrySelect/UserCountrySelect";

export type BasicInfoValues = {
  firstName: string;
  familyName: string;
  pronouns: string;
  position: string;
  new_position: string;
  departament: string;
  new_departament: string;
  managerId: { label: string; value: number } | null;
  employmentType: EmploymentTypes | null;
  country: string;
  location: string;
};

type BasicInfoFormProps = {
  userId: number;
  company: string | null;
  employmentType: string | null;
  initialValues: BasicInfoValues;
  setEditMode: (v: false) => void;
};

const BasicInfoForm: FC<BasicInfoFormProps> = ({
  initialValues,
  userId,
  company,
  employmentType,
  setEditMode,
}) => {
  const { t } = useTranslation("accountSettings");
  const { t: tCommon } = useTranslation("common");
  const { t: tEmployeePage } = useTranslation("employeePage");
  const dispatch = useAppDispatch();
  const isCurrentUserNotAdmin = usePermissionToEmployeeCard(userId, true);
  const isAdmin = usePermissionCheck([
    AppPermissions.ViewAndEditUserEmployeeCards,
  ]);
  const isCurrentUser = useIsCurrentUser();
  const { departmentsData, positionsData, employeeData } = useAppSelector(
    (state) => state.employeePage
  );
  const isEditDisabled = useDisableEditDeleteUser(
    employeeData.data?.basicInfo.source
  );
  const [managerSearchError, setManagerSearchError] = useState<string | null>(
    null
  );

  const [showAddPosition, setShowAddPosition] = useState<boolean>(false);
  const [showAddDepartment, setShowAddDepartment] = useState<boolean>(false);

  const isCorrectCountryValue =
    !initialValues.country ||
    Boolean(getReceiverCountry(initialValues.country));

  useEffect(() => {
    if (isAdmin) {
      dispatch(getBasicInfoFormData());
    }
  }, []);

  const ValidationSchema = Yup.object().shape({
    firstName: Yup.string().required(
      tCommon("validation_fieldIsRequired") as string
    ),
    familyName: Yup.string().required(
      tCommon("validation_fieldIsRequired") as string
    ),
  });

  const employmentTypeOptions = [
    {
      value: EmploymentTypes.fullTimeInOffice,
      label: tEmployeePage(
        `employmentType_${EmploymentTypes.fullTimeInOffice}`
      ),
    },
    {
      value: EmploymentTypes.fullTimeRemote,
      label: tEmployeePage(`employmentType_${EmploymentTypes.fullTimeRemote}`),
    },
    {
      value: EmploymentTypes.partTimeInOffice,
      label: tEmployeePage(
        `employmentType_${EmploymentTypes.partTimeInOffice}`
      ),
    },
    {
      value: EmploymentTypes.partTimeRemote,
      label: tEmployeePage(`employmentType_${EmploymentTypes.partTimeRemote}`),
    },
  ];

  const getEmploymentTypeOptions = useMemo(() => {
    if (
      employmentType &&
      !employmentTypeOptions.find((option) => option.value === employmentType)
    ) {
      return [
        ...employmentTypeOptions,
        { value: employmentType, label: employmentType },
      ];
    }

    return employmentTypeOptions;
  }, [employmentType]);

  if (isAdmin && (departmentsData.isLoading || positionsData.isLoading)) {
    return (
      <div className="loader-container">
        <Loader size={LoaderSize.Small} />
      </div>
    );
  }

  if (isAdmin && (departmentsData.errorMessage || positionsData.errorMessage)) {
    return (
      <>
        <div className="new-wrapper error">{departmentsData.errorMessage}</div>
        <div className="new-wrapper error">{positionsData.errorMessage}</div>
      </>
    );
  }

  if (isAdmin && (!positionsData.data || !departmentsData.data)) {
    return null;
  }

  const handleSubmit = async (
    values: BasicInfoValues,
    { setErrors }: FormikHelpers<BasicInfoValues>
  ) => {
    const requestBody: EditBasicInfoRequest = {
      firstName: values.firstName,
      familyName: values.familyName,
      pronouns: values.pronouns || null,
      departament: values.new_departament || values.departament || null,
      position: values.new_position || values.position || null,
      managerId: values.managerId?.value || null,
      employmentType: values.employmentType,
      country: values.country || null,
      location: values.location || null,
    };

    try {
      await editBasicEmployeeInfo(userId, requestBody);
      toastSuccess(t("userUpdatedToast"));
      dispatch(refreshEmployeePageData(userId));
      dispatch(getUserData(userId));
      if (isCurrentUser) {
        dispatch(getUserInfo(userId));
      }
      setEditMode(false);
    } catch (e) {
      const axiosError = e as AxiosError;
      const error = axiosError.response?.data as serverError;
      toastError(error?.message || tCommon("incorrectData"));
      setErrors(handleServerError(error || {}));
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(props: FormikProps<BasicInfoValues>) => {
        const { values, setFieldValue, isSubmitting } = props;

        return (
          <Form className="form-style basicInfoForm" autoComplete="off">
            {isCurrentUserNotAdmin ? (
              <>
                <AggregateErrorMessage component="div" className="error" />
                <div className="employeePage__item">
                  <BasicInfoList />
                  <label
                    htmlFor="pronouns"
                    className="basicInfoForm__pronouns employeePage__label"
                  >
                    {t("userForm_pronouns")}
                  </label>
                  <div>
                    <Field
                      id="pronouns"
                      type="text"
                      name="pronouns"
                      placeholder={t("userForm_pronouns")}
                      className="form-input"
                      disabled={disableUserFieldForEdit(
                        "pronouns",
                        isEditDisabled
                      )}
                    />
                    <ErrorMessage
                      component="p"
                      name="pronouns"
                      className="error"
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <AggregateErrorMessage component="div" className="error" />
                <div className="employeePage__item">
                  <p className="employeePage__label">{t("userForm_id")}</p>
                  <p className="employeePage__value">{userId}</p>

                  <label htmlFor="firstName" className="employeePage__label">
                    <RequiredFieldLabel labelTitle={t("userForm_firstName")} />
                  </label>
                  <div>
                    <Field
                      id="firstName"
                      type="text"
                      name="firstName"
                      placeholder={t("userForm_firstName")}
                      className="form-input"
                      disabled={disableUserFieldForEdit(
                        "firstName",
                        isEditDisabled
                      )}
                    />
                    <ErrorMessage
                      component="p"
                      name="firstName"
                      className="error"
                    />
                  </div>

                  <label htmlFor="familyName" className="employeePage__label">
                    <RequiredFieldLabel labelTitle={t("userForm_familyName")} />
                  </label>
                  <div>
                    <Field
                      id="familyName"
                      type="text"
                      name="familyName"
                      placeholder={t("userForm_familyName")}
                      className="form-input"
                      disabled={disableUserFieldForEdit(
                        "familyName",
                        isEditDisabled
                      )}
                    />
                    <ErrorMessage
                      component="p"
                      name="familyName"
                      className="error"
                    />
                  </div>

                  <label htmlFor="position" className="employeePage__label">
                    {t("userForm_position")}
                  </label>
                  <div>
                    <Field
                      id="position"
                      name="position"
                      component={CustomSelect}
                      options={(positionsData.data || []).map((p) => {
                        return {
                          value: p,
                          label: p,
                        };
                      })}
                      placeholder={t("userForm_position_placeholder")}
                      isClearable={true}
                      isSearchable={true}
                      isDisabled={disableUserFieldForEdit(
                        "position",
                        isEditDisabled
                      )}
                    />
                    <ErrorMessage
                      component="p"
                      name="position"
                      className="error"
                    />
                  </div>

                  {!isEditDisabled && (
                    <div className="addNewEntityGroup">
                      <button
                        className={classnames("btn-additional", {
                          minusIcon: showAddPosition,
                        })}
                        onClick={() => {
                          if (showAddPosition) {
                            setFieldValue("new_position", "");
                          }
                          setShowAddPosition(!showAddPosition);
                        }}
                        type="button"
                      >
                        {t("userForm_addPosition")}
                      </button>

                      {showAddPosition ? (
                        <label>
                          <Field
                            type="text"
                            name="new_position"
                            placeholder={t("userForm_addPosition_placeholder")}
                            className="form-input"
                          />
                        </label>
                      ) : null}
                    </div>
                  )}

                  <label className="employeePage__label">
                    {t("userForm_departament")}
                  </label>
                  <div>
                    <Field
                      name="departament"
                      component={CustomSelect}
                      options={(departmentsData.data || []).map((p) => {
                        return {
                          value: p,
                          label: p,
                        };
                      })}
                      placeholder={t("userForm_departament_placeholder")}
                      isClearable={true}
                      isSearchable={true}
                      isDisabled={disableUserFieldForEdit(
                        "departament",
                        isEditDisabled
                      )}
                    />
                    <ErrorMessage
                      component="p"
                      name="departament"
                      className="error"
                    />
                  </div>

                  {!isEditDisabled && (
                    <div className="addNewEntityGroup">
                      <button
                        className={classnames("btn-additional", {
                          minusIcon: showAddDepartment,
                        })}
                        onClick={() => {
                          if (showAddPosition) {
                            setFieldValue("new_departament", "");
                          }
                          setShowAddDepartment(!showAddDepartment);
                        }}
                        type="button"
                      >
                        {t("userForm_addDepartament")}
                      </button>

                      {showAddDepartment ? (
                        <label>
                          <Field
                            type="text"
                            name="new_departament"
                            placeholder={t(
                              "userForm_addDepartament_placeholder"
                            )}
                            className="form-input"
                          />
                        </label>
                      ) : null}
                    </div>
                  )}

                  <label htmlFor="managerId" className="employeePage__label">
                    {t("userForm_manager")}
                  </label>
                  <div>
                    <AsyncSelect
                      id="managerId"
                      name="managerId"
                      loadOptions={(inputValue, callback) =>
                        searchUsersOptions(
                          inputValue,
                          callback,
                          setManagerSearchError,
                          userId
                        )
                      }
                      isClearable={true}
                      onChange={(option) => {
                        setFieldValue("managerId", option || null);
                        setManagerSearchError(null);
                      }}
                      className="asyncSelect__container"
                      classNamePrefix="asyncSelect"
                      value={values.managerId}
                      placeholder={tCommon("startTypingToSearch")}
                      noOptionsMessage={() => (
                        <>{tCommon("select_noItems") as string}</>
                      )}
                      isDisabled={disableUserFieldForEdit(
                        "managerId",
                        isEditDisabled
                      )}
                    />
                    <ErrorMessage
                      component="p"
                      name="managerId"
                      className="error"
                    />
                    {managerSearchError && (
                      <div className="new-wrapper error">
                        {managerSearchError}
                      </div>
                    )}
                  </div>

                  <p className="employeePage__label">{t("userForm_company")}</p>
                  <p className="employeePage__value">{company}</p>

                  <label
                    htmlFor="employmentType"
                    className="employeePage__label"
                  >
                    {tEmployeePage("basicInfo_employmentType")}
                  </label>
                  <div className="basicInfoForm__employmentType">
                    <Field
                      id="employmentType"
                      name="employmentType"
                      component={CustomSelect}
                      isClearable={true}
                      options={getEmploymentTypeOptions}
                      placeholder={tEmployeePage("basicInfo_employmentType")}
                      isDisabled={disableUserFieldForEdit(
                        "employmentType",
                        isEditDisabled
                      )}
                    />
                    <ErrorMessage
                      component="p"
                      name="employmentType"
                      className="error"
                    />
                  </div>

                  <label htmlFor="country" className="employeePage__label">
                    {t("userForm_country")}
                  </label>
                  <div>
                    <UserCountrySelect
                      isEditDisabled={disableUserFieldForEdit(
                        "country",
                        isEditDisabled
                      )}
                      isCountryCorrect={isCorrectCountryValue}
                    />
                    <ErrorMessage
                      component="p"
                      name="country"
                      className="error"
                    />
                  </div>

                  <label htmlFor="location" className="employeePage__label">
                    {t("userForm_location")}
                  </label>
                  <div>
                    <Field
                      id="location"
                      type="text"
                      name="location"
                      placeholder={t("userForm_location")}
                      className="form-input"
                      disabled={disableUserFieldForEdit(
                        "location",
                        isEditDisabled
                      )}
                    />
                    <ErrorMessage
                      component="p"
                      name="location"
                      className="error"
                    />
                  </div>

                  {isAdmin && (
                    <>
                      <label htmlFor="pronouns" className="employeePage__label">
                        {t("userForm_pronouns")}
                      </label>
                      <div>
                        <Field
                          id="pronouns"
                          type="text"
                          name="pronouns"
                          placeholder={t("userForm_pronouns")}
                          className="form-input"
                          disabled={disableUserFieldForEdit(
                            "pronouns",
                            isEditDisabled
                          )}
                        />
                        <ErrorMessage
                          component="p"
                          name="pronouns"
                          className="error"
                        />
                      </div>
                    </>
                  )}
                </div>
              </>
            )}

            <div className="formButtons">
              <button
                type="button"
                className="btn-tertiary formButtons__formBtn"
                disabled={isSubmitting}
                onClick={() => setEditMode(false)}
              >
                {tCommon("form_cancel")}
              </button>
              <button
                type="submit"
                className="btn-primary formButtons__formBtn"
                disabled={isSubmitting}
              >
                {isSubmitting ? <ButtonLoader /> : tCommon("form_save")}
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default BasicInfoForm;
